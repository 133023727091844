<template>
  <v-container
    v-bind="binding"
    fluid
    grid-list-xl
  >
    <breadcrumbs
      v-if="breadcrumbs"
      :breadcrumbs="breadcrumbs"
    />
    <h3
      v-if="title"
      class="headline grey--text text-xs-center hidden-sm-and-down"
    >
      {{ title }}
    </h3>
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md8
        offset-md2
      >
        <slot />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  components: {
    Breadcrumbs: () => import(/* webpackChunkName: "breadcrumbs" */ '@/components/Breadcrumbs'),
  },
  props: {
    breadcrumbs: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    binding: function () {
      return this.$vuetify.breakpoint.xsOnly ? { class: 'mx-0 px-0' } : {}
    },
  },
}
</script>
